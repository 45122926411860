<template>
    <div>
        <main-layout>
            <div class="row">
                <div class="col-12">
                    <div class="statbox widget box box-shadow">
                        <div class="widget-header">
                            <div class="row">
                                <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                    <h4>Place new order</h4>
                                </div>
                            </div>
                        </div>
                        <div class="widget-content widget-content-area">
                            <no-order-weekend-notification></no-order-weekend-notification>
                            <validation-errors :errors="validationErrors"></validation-errors>
                            <div v-if="customerSubscription != null && customerSubscription != ''">
                                <div v-if="customerSubscription.exceeded == false">
                                    <p>You still have an active subscription, do you want to pay using your subscription?</p>
                                    
                                    <div class="n-chk">
                                        <label class="new-control new-radio radio-warning">
                                            <input type="checkbox" name="pay-with-sub" class="new-control-input" v-model="payWithSub">
                                            <span class="new-control-indicator"></span> Yes, I want to pay using my subscription
                                        </label>
                                    </div>
                                    <div class="alert alert-info" v-if="payWithSub == true">You will have {{ customerSubscription.order_limit - (customerSubscription.order_count + deliveries.length) }} orders left</div>
                                </div>
                            </div>
                            <div id="toggleAccordion">
                                <div class="card">
                                    <div class="card-header bg-secondary-light">
                                        <section class="mb-0 mt-0 text-white">
                                            <div role="menu" class="collapsed" data-toggle="collapse" data-target="#pickupAccordion" aria-expanded="true" aria-controls="defaultAccordionOne">
                                                <i class="fa fa-cart-plus"></i> Add pickup details  
                                            </div>
                                        </section>
                                    </div>

                                    <div id="pickupAccordion" class="collapse show" aria-labelledby="..." >
                                        <div class="card-body">
                                            <div class="" v-if="payWithSub == false">
                                                <!-- <div class="form-group">
                                                    <label for="Pickup Address">Address *</label>
                                                    <input type="text" class="form-control" name="pickupAddress" @keyup="findAddress($event.target, 'pickupLocation')" v-model="pickupLocation.full_address" placeholder="Pickup Address">
                                                    <div class="help-text text-danger font-weight-bold" v-if="pickupLocation.address_not_found === true">
                                                        Your pickup location could not be found. Please try another location.
                                                    </div>
                                                </div> -->
                                                <div class="form-group">
                                                    <label for="Pickup Address">Address *</label>
                                                    <input type="text" class="form-control" name="pickupAddress" v-model="pickupLocation.full_address" placeholder="Pickup Address">
                                                    <div class="help-text text-danger font-weight-bold" v-if="pickupLocation.address_not_found === true">
                                                        Your pickup location could not be found. Please try another location.
                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="form-group col-12 col-sm-6">
                                                        <label for="State">State</label>
                                                        <multiselect value="id" v-model="pickupLocation.state" :options="pickupStatesList" track-by="id" label="name" @select="(selected, id) => {pickupLocation.state_id = selected.id; getPickupCities(selected, id)}" select-label="" deselect-label=""></multiselect>
                                                        <!-- <select name="pickupstate" id="pickupstate" class="select2 form-control" v-model="pickupLocation.state_id" @change="getPickupCities">
                                                            <option :value="s.id" v-for="s in pickupStatesList" :key="s.id">{{ s.name }}</option>
                                                        </select> -->
                                                    </div>
                                                    <div class="form-group col-12 col-sm-6">
                                                        <label for="City">City</label>
                                                        <multiselect value="id" v-model="pickupLocation.lga" :options="pickupCitiesList" track-by="id" label="name" @select="(selected, id) => {pickupLocation.lga_id = selected.id}" select-label="" deselect-label=""></multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="form-group">
                                                <label for="Picup Address">Choose Address *</label>
                                                <select name="pickupAddress" id="pickupAddress" class="form-control" @change="onSelectSubscriptionAddress($event)">
                                                    <option value="">Select pickup address</option>
                                                    <option :value="index" v-for="(l, index) in customerSubscription.user_locations" :key="l.id">{{ l.address }}</option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label for="Sender Name">Name *</label>
                                                <input type="text" name="senderName" id="senderName" class="form-control" placeholder="Sender Name" v-model="senderName" required>
                                            </div>
                                            <div class="form-group">
                                                <label for="Sender Email">Email *</label>
                                                <input type="email" name="senderEmail" id="senderEmail" class="form-control" placeholder="Sender Email" v-model="senderEmail" required>
                                            </div>
                                            <div class="form-group">
                                                <label for="Sender Phone number">Phone number *</label>
                                                <input type="tel" name="senderPhone" id="senderPhone" class="form-control" placeholder="Phone Number" v-model="senderPhone" required>
                                            </div>
                                            <div class="form-group">
                                                <label for="Item description">Item description *</label>
                                                <input type="text" name="item_description" id="item_description" class="form-control" placeholder="What's the item you're delivering?" v-model="itemDescription" required>
                                            </div>
                                            <div class="form-group">
                                                <label for="Extra notes">Extra details about the pickup</label>
                                                <textarea name="pickupExtra" id="extraNote" class="form-control" placeholder="Is there anything else we should know about the pickup?" v-model="pickupExtraInfo"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card" v-for="(d, index) in deliveries" :key="index">
                                    <div class="card-header">
                                        <section class="mb-0 mt-0">
                                            <div role="menu" class="collapsed disabled" data-toggle="collapse" :data-target="`#deliveryAccordion${index}`" aria-expanded="false" aria-controls="defaultAccordionTwo">
                                                <i class="fa fa-truck"></i> Enter delivery details ({{ index+1 }})
                                            </div>
                                        </section>
                                    </div>
                                    <div :id="`deliveryAccordion${index}`" class="collapse show" aria-labelledby="..." >
                                        <div class="card-body">
                                            <div class="form-group">
                                                <label for="Delivery Address">Address *</label>
                                                <input type="text" class="form-control" name="deliveryAddress" v-model="d.full_address" placeholder="Delivery Address" required>
                                            </div>
                                            <div class="form-row">
                                                <div class="col-12 col-sm-4 form-group">
                                                    <label for="country">Country</label>
                                                    <multiselect :options="countriesList" v-model="d.country" track-by="id" label="name" @select="(selected, id) => {d.country_id = selected.id; getDeliveryStates(selected, id, index)}" select-label="" deselect-label=""></multiselect>
                                                </div>
                                                <div class="col-12 col-sm-4 form-group">
                                                    <label for="State">State</label>
                                                    <multiselect :options="d.deliveryStatesList" v-model="d.state" track-by="id" label="name" @select="(selected, id) => {d.state_id = selected.id; getDeliveryCities(selected, id, index)}" select-label="" deselect-label=""></multiselect>
                                                </div>
                                                <div class="col-12 col-sm-4 form-group">
                                                    <label for="State">City</label>
                                                    <multiselect :options="d.deliveryCitiesList" v-model="d.lga" track-by="id" label="name" @select="(selected, id) => {d.lga_id = selected.id}" select-label="" deselect-label=""></multiselect>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="Recipient Name">Name *</label>
                                                <input type="text" name="recipientName" class="form-control" placeholder="Name" v-model="d.recipientName" required>
                                            </div>
                                            <div class="form-group">
                                                <label for="Recipient Email">Email</label>
                                                <input type="email" name="recipientEmail" class="form-control" placeholder="Email" v-model="d.recipientEmail">
                                            </div>
                                            <div class="form-group">
                                                <label for="Recipient Phone number">Phone number *</label>
                                                <input type="tel" name="recipientPhone" id="recipientPhone" class="form-control" placeholder="Phone Number" v-model="d.recipientPhone" required>
                                            </div>
                                            <div class="form-group">
                                                <label for="Extra info">Extra details about the delivery</label>
                                                <textarea name="deliveryExtra" id="deliveryExtra" placeholder="Is there anything else we should know about the delivery?" v-model="d.deliveryExtraInfo" class="form-control"></textarea>
                                            </div>
                                            <div v-if="deliveries.length > 1">
                                                <button class="btn btn-danger float-right mb-3" @click="removeDeliveryLocation(index)">Remove</button>
                                            </div>
                                            <button class="btn btn-secondary"  @click="addNewDeliveryLocation">Add new delivery</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3 mt-3">
                                    <div class="">
                                        <button class="btn btn-outline-dark" data-toggle="collapse" data-target="#summaryAccordion" @click="getEstimate">Get estimate</button>
                                    </div>
                                </div>
                                <div class="card" v-show="estimateAvailable">
                                    <div class="card-header">
                                        <section class="mb-0 mt-0">
                                            <div role="menu" class="collapsed" data-toggle="collapse" data-target="#summaryAccordion" aria-expanded="false" aria-controls="defaultAccordionThree">
                                                <i class="fa fa-receipt"></i> Confirm order
                                            </div>
                                        </section>
                                    </div>
                                    <div id="summaryAccordion" class="collapse" aria-labelledby="..." >
                                        <div class="card-body">
                                            <div v-if="estimateFound === true">
                                                Total Price: <span class="h5">{{formatNumberToCurrency(totalCost)}}</span>
                                                <br><br>
                                                <div class="alert alert-info mb-4" role="alert">
                                                    <i class="fa fa-exclamation-circle"></i> The price displayed is subject to change. Our rider will be on the way to you shortly to pickup your order
                                                </div>
                                                <div class="mt-3 mb-3 pb-3">
                                                    <button class="btn btn-warning float-right" @click="createOrder">Confirm order</button>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <span class="h5">Price will be calculated after you submit order</span>
                                                <div class="alert alert-info mb-4" role="alert">
                                                    <i class="fa fa-exclamation-circle"></i>Our rider will be on the way to you shortly to pickup your order. <br> Your order price will be determined after our rider picks up your order.
                                                </div>
                                                <div class="mt-3 mb-3 pb-3">
                                                    <button class="btn btn-warning float-right" @click="createOrder">Confirm order</button>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </main-layout>
    </div>
</template>
<script>
import { useToast } from 'vue-toastification';
import appconfig from '../../appconfig';
import Auth from '../../helpers/auth';

import MainLayout from "../../layouts/MainLayout";
import instance from '../../presenter/api/network';
import { loadScripts } from '../../utils';
import ValidationErrors from '../../components/ValidationErrors.vue';
import NoOrderWeekendNotification from '../../components/NoOrderWeekendNotification.vue';
import Multiselect from '@suadelabs/vue3-multiselect'
import '@suadelabs/vue3-multiselect/dist/vue3-multiselect.css'
import $ from "jquery"
export default {
    components: {MainLayout, ValidationErrors, Multiselect, NoOrderWeekendNotification},
    mounted(){
        
        window.mapCallback = this.findAddress;
        this.prepopulateDeliveryDetails();
        this.getSubscriptionInfo();
        this.getCountries().then(() => this.getPickupStates()).catch((err) => console.log(err));
        loadScripts([
            "assets/vendor/js/components/ui-accordions.js",
            "assets/plugins/autocomplete/jquery.autocomplete.js",
            // `https://maps.googleapis.com/maps/api/js?key=${appconfig.googleMapsApiKey}&callback=mapCallback&libraries=places`
        ])
        
        if (!window.google){
            loadScripts([
                `https://maps.googleapis.com/maps/api/js?key=${appconfig.googleMapsApiKey}&libraries=places`
            ])
        }
        
    },
    data(){
        return {
            pickupLocation: {
                full_address: "",
                country: "",
                state: "",
                lga: "",
                street: "",
                country_id: "",
                state_id: "",
                lga_id: "",
                google_address_selected: false,
                address_not_found: false,
            },
            senderName: Auth.user().first_name + " " + Auth.user().last_name,
            senderEmail: Auth.user().email,
            senderPhone: Auth.user().phone,
            pickupExtraInfo: "",
            // deliveryExtraInfo: "",
            pickupLandmark: "",
            // deliveryLocation: {
            //     full_address: "",
            //     country: "",
            //     state: null,
            //     lga: null,
            //     street: "",
            //     country_id: "",
            //     state_id: "",
            //     lga_id: "",
            //     google_address_selected: false,
            //     address_not_found: false
            // },
            deliveries: [],
            deliveryTemplate: {
                full_address: "",
                country: "",
                state: "",
                lga: "",
                street: "",
                country_id: "",
                state_id: "",
                lga_id: "",
                google_address_selected: false,
                address_not_found: false,
                recipientName: "",
                recipientEmail: "",
                recipientPhone: "",
                deliveryExtraInfo: "",
                deliveryStatesList: [],
                deliveryCitiesList: []
            },
            // recipientName: "",
            // recipientEmail: "",
            // recipientPhone: "",
            itemWeight: "",
            totalCost: 0,
            estimateFound: true,
            itemDescription: "",
            completedOrderId: null,
            orderCompleted: false,
            estimateAvailable: false,
            validationErrors: "",
            customerSubscription: null,
            payWithSub: false,
            selectedSubscriptionLocation: "",
            countriesList: [],
            defaultCountry: null,
            pickupStatesList: [],
            pickupCitiesList: [],
            // deliveryStatesList: [],
            // deliveryCitiesList: []
        }
    },
    methods: {
        getCountries(){
            return instance.get("getCountryData").then(res => {
                    this.countriesList = res.data.data;
                    let countryNigeria = this.countriesList.find(item => {
                        return item.name.toLowerCase() == "nigeria";
                    });
                    this.defaultCountry = this.pickupLocation.country = countryNigeria; // = this.deliveryTemplate.country = countryNigeria;
                    this.pickupLocation.country_id = this.deliveryTemplate.country_id = countryNigeria.id;
                    this.addNewDeliveryLocation();
                    
                }).catch(err => {
                    console.log(err);
                })
        },
        getPickupStates(){
            
            instance.get(`getStateData?country_id=${this.defaultCountry.id}&for=pickup`).then(res => {
                this.pickupStatesList = res.data.data;
                // this.deliveryTemplate.deliveryStatesList = res.data.data;
                // this.deliveries.forEach((d, index) => {
                //     this.deliveries[index].deliveryStatesList = res.data.data;
                // })
            })
        },
        getPickupCities(selectedOption, id){
            this.pickupLocation.lga = "";
            instance.get(`getCityData?state_id=${selectedOption.id}&for=pickup`).then(res => {
                this.pickupCitiesList = res.data.data;
            })
        },
        getDeliveryStates(selectedOption, id, index){
            this.deliveries[index].state = "";
            this.deliveries[index].lga = "";
            instance.get(`getStateData?country_id=${selectedOption.id}&for=delivery`).then(res => {
                this.deliveries[index].deliveryStatesList = res.data.data;
            })
        },
        getDeliveryCities(selectedOption, id, index){
            this.deliveries[index].lga = "";
            instance.get(`getCityData?state_id=${selectedOption.id}&for=delivery`).then(res => {
                this.deliveries[index].deliveryCitiesList = res.data.data;
            })
        },
        prepopulateDeliveryDetails(){
            if (localStorage.getItem("orderPrePlacementDetails") != null){
                let pickup = JSON.parse(localStorage.getItem("orderPrePlacementDetails")).pickup;
                let delivery = JSON.parse(localStorage.getItem("orderPrePlacementDetails")).delivery;
                if (pickup != undefined){
                    this.pickupLocation = {...pickup}
                }
                if (delivery != undefined){
                    this.deliveries = [...delivery];
                }
            }
        },
        getSubscriptionInfo(){
            this.$root.startRequestLoader();
            instance.get("customer/user-subscription-information").then(res => {
                this.customerSubscription = res.data.data.current_subscription_information;
            }).catch(err => {

            }).finally(() => {
                this.$root.endRequestLoader();
            })
        },
        onSelectSubscriptionAddress(e){
            let target = e.target;
            if (target.value == ""){
                alert("You need to select an address");
                return;
            }
            const location = this.customerSubscription.user_locations[target.value];
            this.pickupLocation.full_address = location.address;
            this.pickupLocation.lga_id = location.city_id;
            this.pickupLocation.state_id = location.state_id;
            this.pickupLocation.country_id = location.country_id;
            this.selectedSubscriptionLocation = location;
        },
        addNewDeliveryLocation(){
            if (this.payWithSub == true){
                let ordersLeft = this.customerSubscription.order_limit - this.customerSubscription.order_count - this.deliveries.length;
                if (ordersLeft < 1){
                    alert("You have reached maximum number of orders you can use your subscription for.");
                    return false;
                }
            }
            this.deliveries.push({...this.deliveryTemplate});
        },
        removeDeliveryLocation(index){
            this.deliveries.splice(index, 1);
        },
        findAddress(input, modelToUpdate) {
            let autocomplete = new window.google.maps.places.Autocomplete(input, {
                componentRestrictions: {country: ["ng"]},
                // fields: ["address_components"],
                types: ["address"],
            })

            autocomplete.setFields(["address_components"]);
            
            // When the user selects an address from the drop-down, populate the
            // address fields in the form.
            autocomplete.addListener("place_changed", () => {
                let place = autocomplete.getPlace();
                let country;
                let state;
                let lga = [];
                let street;
                // console.log(place.address_components);
                for (const component of place.address_components) {
                    const componentType = component.types[0];

                    switch (componentType) {
                        case "route": {
                            street = component.long_name;
                            break;
                        }
                        // case "locality": 
                        //     lga.push(component.long_name);
                        //     break;
                        case "neighborhood":
                            lga.push(component.long_name);
                            break;
                        case "administrative_area_level_2": {
                            lga.push(component.long_name);
                            break;
                        }
                        case "administrative_area_level_1": {
                            state = component.long_name;
                            break;
                        }
                        case "country":
                            country = component.long_name;
                            break;
                    }
                }
                let data = {country, state, lga, street, full_address: input.value, google_address_selected: true};
                let city;
                if (Array.isArray(data.lga)){
                    city = data.lga.join(",");
                }else{
                    city = data.lga;
                }
                this[modelToUpdate] = {...data};
                instance.get(`search-place`, {params: {city: city, state: data.state, country: data.country}}).then(res => {
                    
                    if (res.data.message == "found"){
                        
                        data.country_id = res.data.data.country_id;
                        data.state_id = res.data.data.state_id;
                        data.lga_id = res.data.data.city_id;
                        data.address_not_found = false;
                    }
                    
                    this[modelToUpdate] = {...data};
                }).catch(err => {
                    
                    data.address_not_found = true;
                    this[modelToUpdate] = {...data}
                })
            });
        },
        getEstimate(){
            this.$root.startRequestLoader();
            let pickup = this.pickupLocation;
            let delivery = [];
            this.deliveries.forEach(d => {
                delivery.push({
                    country_id: d.country_id,
                    state_id: d.state_id,
                    city_id: d.lga_id
                })
            });
            
            let formData = {
                from: {country_id: pickup.country_id, state_id: pickup.state_id, city_id: pickup.lga_id},
                to: delivery
                // to: {country_id: delivery.country_id, state_id: delivery.state_id, city_id: delivery.lga_id}
            }
            
            instance.post(`getPriceEstimate`, formData).then(res => {
                
                let response = res.data.data;
                if (res.data.message == "no price"){
                    this.estimateFound = false
                }else{
                    this.estimateFound = true
                    this.totalCost = res.data.data.total;
                }
                this.estimateAvailable = true;
            }).catch(err => {
                if (err.response){
                    useToast().error(err.response?.message ?? "There was an error getting estimates, please try again")
                }else{
                    useToast().error("An unknown error occured");
                }
                
            }).finally(() => {
                this.$root.endRequestLoader();
            })
            
        },
        createOrder(){
            this.$root.startRequestLoader();
            let delivery = [];
            this.deliveries.forEach(d => {
                delivery.push({
                    delivery_country_id: d.country_id,
                    delivery_state_id: d.state_id,
                    delivery_city_id: d.lga_id,
                    delivery_address: d.full_address,
                    delivery_name: d.recipientName,
                    delivery_email: d.recipientEmail,
                    delivery_phone: d.recipientPhone,
                    delivery_extra_information: d.deliveryExtraInfo
                })
            });
            let data = {
                user_id: Auth.user().id,
                pickup_name: this.senderName,
                pickup_email: this.senderEmail,
                pickup_address: this.pickupLocation.full_address,
                pickup_city_id: this.pickupLocation.lga.id,
                pickup_state_id: this.pickupLocation.state.id,
                pickup_country_id: this.pickupLocation.country.id,
                pickup_phone: this.senderPhone,
                item_description: this.itemDescription,
                pickup_extra_information: this.pickupExtraInfo,
                delivery_details: delivery
                // delivery_name: this.recipientName,
                // delivery_email: this.recipientEmail,
                // delivery_phone: this.recipientPhone,
                // delivery_address: this.deliveryLocation.full_address,
                // delivery_city_id: this.deliveryLocation.lga.id,
                // delivery_state_id: this.deliveryLocation.state.id,
                // delivery_country_id: this.deliveryLocation.country.id,
                // delivery_extra_information: this.deliveryExtraInfo,
            }
            if (this.payWithSub == true){
                data.use_subscription = true;
                data.user_subscription_location_id = this.selectedSubscriptionLocation.id;
            }
            instance.post("customer/create-order", data).then(res => {
                // this.orderId = res.data.data.orderID;
                let orders = [];
                orders = res.data.data;
                let orderIds = orders.map(o => o.orderID);
                this.confirmOrder(orderIds);
            }).catch(err => {
                if (err.response != undefined){
                    useToast().error(err.response.data.message ?? "Unable to create your order at the moment");
                    if (err.response.status == 400) {
                        this.validationErrors = err.response.data.data;
                    }
                    
                }else{
                    useToast().error("An unknown error occured, please refresh and try again");
                }
                this.$root.endRequestLoader();
            })
        },
        confirmOrder(orderId){
            let postData = {
                orderID: orderId,
                user_id: Auth.user().id
            }
            instance.post("customer/confirm-order", postData).then(res => {
                useToast().success("Your order has been placed successfully");
                this.orderCompleted = true;
                localStorage.removeItem("orderPrePlacementDetails");
                this.$root.endRequestLoader();
                // this.$router.push(`/orders/${orderId}/view`);
                this.$router.push(`/orders/`);

            }).catch(err => {
                this.$root.endRequestLoader();
                if (err.response != undefined){
                    useToast().error(err.response.data?.message ?? "There was an error placing your order, please try again");
                }
                
            })
        }
    }
}
</script>
<style scoped>
@import url("../../assets/lib/vendor/css/components/tabs-accordian/custom-accordions.css");
@import url("../../assets/lib/plugins/autocomplete/autocomplete.css");
@import url("../../assets/lib/vendor/css/forms/theme-checkbox-radio.css");
@import url("../../assets/lib/plugins/select2/select2.min.css");
</style>