<template>
    <div>
        <div v-if="isWeekend()" class="alert alert-danger">
            <i class="fa fa-exclamation"></i>
            Dear customer, please note that we do not operate on weekends.
            Orders placed during the weekend will be processed on Monday.
        </div>
    </div>
</template>
<script>
import dayjs from 'dayjs'
export default {
    name: "NoOrderWeekendNotification",
    methods: {
        isWeekend(){
            let currentDay = dayjs().get('day');
            return currentDay == 0 || currentDay == 6
        }
    }
    
}
</script>